import { debounce } from '../../resources/js/utils/delays'

const navLinks = document.querySelectorAll('.image-tabs__image-tabs-nav-link')

const debouncedMouseOut = debounce(async () => {
  const defaultImage = document.querySelector('#image-tab0')
  const itemContent = document.querySelectorAll('.image-tabs__image-content')

  if (!document.querySelector('.image-tabs__image-tabs-nav-item.active')) {
    itemContent.forEach(item => item.classList.remove('active'))
    defaultImage?.classList.add('active')
  }
}, 150)

const debouncedMouseEnter = debounce(async () => {
  const itemContent = document.querySelectorAll('.image-tabs__image-content')
  const current = document.querySelector('.image-tabs__image-tabs-nav-item.active')

  if (current?.classList?.contains('active')) {
    itemContent.forEach(content => content.classList.remove('active'))
    const link = current.querySelector('.image-tabs__image-tabs-nav-link')
    const activeImage = document.querySelector(`${ link?.dataset.href}`)
    activeImage.classList.add('active')
  }
}, 150)

navLinks.forEach(navLink => {
  navLink.addEventListener('mouseenter', function (e) {
    e.target.parentElement.classList.add('active')

    debouncedMouseEnter()
  })

  navLink.addEventListener('mouseout', async function (e) {
    e.target.parentElement.classList.remove('active')

    debouncedMouseOut()
  })
})
